import styled from 'styled-components';
import OndaFooterMobile from '../../assets/svg/ondafooterdesk.svg';

export const FooterSection = styled.footer`
  background-color: black;
  color: white;
  display: grid;
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr; 
  grid-template-areas: 
    "taom"
    "media"
    "services";
  width: 100%;
  font-family: 'Poppins';
  text-align: center;
  padding: 0 0 2vh 0;

  > section {
    padding: 1em;
    color: #b5852f;
  }

  @media screen and (min-width: 768px) {
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr;
    grid-template-areas: 
      "services taom media";
    background-image: url(${OndaFooterMobile});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2vh 0;
    margin: 4vh 0 0 0;

    section {
      font-size: 1.2em;
    }
  }
`;

export const TaomSection = styled.section`
  grid-area: taom;

  @media screen and (min-width: 768px) {
    margin: auto 0;
  }
`;

export const MediaSection = styled.section`
  grid-area: media; 
  background-image: url(${OndaFooterMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  h3 {
    margin: 0;
    font-weight: normal;
  }

  @media screen and (min-width: 768px) {
    margin: auto 0;
    grid-area: media; 
    background: none;
  }
`;

export const SocialMediaLogos = styled.div`
  padding: 3vw;
  svg {
    width: 23%;
    padding: 5% 0%;
  }

  @media screen and (min-width: 768px) {
    padding: 1vw;
    svg {
      margin: 0% 1%;
    }
  }
`;

export const CallToAction = styled.button`
  border-radius: 50px;
  border: 2px solid #b5852f;
  color: #b5852f;
  padding: 0.5vh 0vw;
  background-color: transparent; 
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: bolder;
  margin: auto;
  width: 75%;
  display: flex;
  justify-content: space-around;
  transition: 0.3s;

  span {
    display: flex;
    align-items: center;
    padding-left: 5%;

    svg {
      path {
        transition: 0.3s;
      }
      padding: 1.5vh;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #b5852f;
    color: black;
    border: 2px solid black;

    svg {
      padding: 1.5vh;
      path {
        fill: black;
      }
    }
  }

  @media screen and (min-width: 768px) {
    //max-width: 60%;

    span {
      padding-left: 10%;
    }
  }
`;

export const ServicesSection = styled.section`
  grid-area: taom;
  grid-area: services;
  
  @media screen and (min-width: 768px) {
    margin: auto;
    text-align: left;
  }
`;

export const ServicesList = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  a {
    text-decoration: none;
    &:visited {
      color: #e9bd6d;
    }
  }

  li {
    margin: 0 0 1em 0;
  }

  @media screen and (min-width: 768px) {
    li {
      margin: 1em 0 1.5em 0;
    }
  }
`;