import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Header.styled';
import { Hamburger, MobileMenu, Navbar, Menu } from '../styles/components/Header.styled';
import LogoDoradoNegro from './svg/LogoDoradoNegro';

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setShowMobileMenu(false);
            }
        }
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }


  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const variants = {
    hidden: { transform: "translateY(-150%)" },
    visible: { transform: "translateY(0%)" }
  }

  return (
    <Navbar ref={wrapperRef}>
      <Link onClick={() => {setShowMobileMenu(false)}} to="/"><LogoDoradoNegro placed="Header" /></Link>
      <Hamburger onClick={() => {setShowMobileMenu(!showMobileMenu)}}>
        <div className="top"></div>
        <div className="middle"></div>
        <div className="bottom"></div>
      </Hamburger>
      <MobileMenu 
        initial={false}
        animate={ showMobileMenu ? "visible" : "hidden" }
        variants={variants} >
        <li onClick={() => {setShowMobileMenu(!showMobileMenu)}}><Link to="/dale-play">DALE . PLAY</Link></li>
        <li onClick={() => {setShowMobileMenu(!showMobileMenu)}}><Link to="/servicios">SERVICIOS</Link></li>
        <li onClick={() => {setShowMobileMenu(!showMobileMenu)}}><Link to="/watch-this">WATCH . THIS</Link></li>
        <li onClick={() => {setShowMobileMenu(!showMobileMenu)}}><Link to="/make-it-possible">MAKE . IT . POSSIBLE</Link></li>
      </MobileMenu>
      <Menu>
        <li><Link to="/dale-play">DALE . PLAY</Link></li>
        <li><Link to="/servicios">SERVICIOS</Link></li>
        <li><Link to="/watch-this">WATCH . THIS</Link></li>
        <li><Link to="/make-it-possible">MAKE . IT . POSSIBLE</Link></li>
      </Menu>
    </Navbar>
  );
}
 
export default Header;