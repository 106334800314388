import React from 'react';
import { HeroWrapper, InfoWrapper } from '../styles/pages/HomePage.styled';

function HomePage(){
  return (
    <>
      <HeroWrapper>
        <div>
          <h1 className="title">El arte de <br/>hacer <br/>resonar al <br/>mundo</h1>
          <h2 className="title">Let . s produce <span>magic</span></h2>
        </div>
      </HeroWrapper>
      <InfoWrapper className="content">
        <h4>Let . s produce <span>magic</span></h4>
        <p>Ta Om es una empresa audiovisual donde el arte y la tecnología trabajan en conjunto para enfocar los sentidos y darle un nombre a la creatividad.</p>
      </InfoWrapper>
    </>
  );
};

export default HomePage;
