import * as React from "react"

function YoutubeLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 193.2 154.56"
      {...props}
    >
      <g data-name="Capa 2">
        <path
          d="M189.16 21.12A24.2 24.2 0 00172.08 4C157 0 96.6 0 96.6 0S36.19 0 21.12 4A24.2 24.2 0 004 21.12C0 36.19 0 77.28 0 77.28s0 41.09 4 56.16a24.2 24.2 0 0017.08 17.08c15.07 4 75.48 4 75.48 4s60.41 0 75.48-4a24.18 24.18 0 0017.08-17.08c4-15.07 4-56.16 4-56.16s.08-41.09-3.96-56.16zM77.28 102.38v-50.2A4.83 4.83 0 0184.53 48L128 73.1a4.83 4.83 0 010 8.36l-43.47 25.1a4.83 4.83 0 01-7.25-4.18z"
          fill="#b5852f"
          data-name="Capa 1"
        />
      </g>
    </svg>
  )
}

export default YoutubeLogo
