import React from 'react';
import WorkInProgress from './WorkInProgress';

function WatchThis(){
  return (
    <WorkInProgress page="WatchThis"/>
  );
};

export default WatchThis;
