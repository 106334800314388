import React from 'react';
import WorkInProgress from './WorkInProgress';

function DalePlay(){
  return (
    <WorkInProgress page="DalePlay"/>
  );
};

export default DalePlay;
