import styled from 'styled-components';

export const LogoHeader = styled.svg`
  width: 5em;
  padding: 0 2.5vh;
`;

export const LogoFooter = styled.svg`
  width: 10em;
  padding: 2.5vw;
`;