import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Navbar = styled.nav`
  height: 10vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins';

  @media screen and (min-width: 768px) {
    padding: 0 3vw;
  }
`;

export const Hamburger = styled.div`
  padding: 1.5vh 4vh;
  width: 30px;
  cursor: pointer;

  div {
    background-color: #b5852f;
    height: 3px;
    margin: 6px 0px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileMenu = styled(motion.ul)`
  width: 100vw;
  margin: 0px;
  text-align: center;
  position: absolute;
  top: 10vh;
  right: 0;
  background-color: rgba(0,0,0,0.95);
  padding: 5% 0%;
  list-style: none;
  transform: translateY(-150%);
  z-index: 0;

  li {
    padding: 0.5em 0em;
    font-size: 2em;

    a {
      color: #b5852f;
      text-decoration: none;
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 2vh;
  justify-content: space-around;
  align-items: center;
  width: 55%;  

  li {
    max-width: 100px;

    a {
      color: #b5852f;
      font-weight: bold;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;