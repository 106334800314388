import * as React from "react"

function LinkedinLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 163.89 163.89"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:#b5852f}"}</style>
      </defs>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__Capa_1-2" data-name="Capa 1">
          <path
            className="prefix__cls-1"
            d="M163.85 163.89v-60.12c0-29.4-6.33-52-40.71-52-16.52 0-27.61 9.07-32.14 17.67h-.48V54.47H58v109.41h33.91V109.7c0-14.26 2.7-28.06 20.37-28.06 17.4 0 17.66 16.28 17.66 29v53.27zM2.7 54.47h34v109.42h-34zM19.68 0a19.77 19.77 0 1019.68 19.68A19.69 19.69 0 0019.68 0z"
          />
        </g>
      </g>
    </svg>
  )
}

export default LinkedinLogo
