import * as React from "react"

function TwitterLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 189.17 153.72"
      {...props}
    >
      <g data-name="Capa 2">
        <path
          d="M189.17 18.2a77.55 77.55 0 01-22.29 6.11 38.89 38.89 0 0017.06-21.47 78 78 0 01-24.65 9.42 38.82 38.82 0 00-67.13 26.55 39.53 39.53 0 001 8.84 110.18 110.18 0 01-80-40.56 38.87 38.87 0 0012 51.82A38.72 38.72 0 017.6 54.06v.48A38.83 38.83 0 0038.73 92.6a38.8 38.8 0 01-17.52.66 38.85 38.85 0 0036.25 27 77.85 77.85 0 01-48.2 16.62 76.62 76.62 0 01-9.26-.59 109.86 109.86 0 0059.49 17.43c71.39 0 110.41-59.13 110.41-110.42 0-1.68 0-3.35-.1-5a79 79 0 0019.37-20.1z"
          fill="#b5852f"
          data-name="Capa 1"
        />
      </g>
    </svg>
  )
}

export default TwitterLogo
