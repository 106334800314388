import * as React from "react"

function FacebookLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.91 191.8"
      {...props}
    >
      <g data-name="Capa 2">
        <path
          d="M78.4 31.85h17.51V1.35A227.55 227.55 0 0070.4 0C45.15 0 27.86 15.88 27.86 45.06v26.86H0V106h27.86v85.8H62V106h26.75L93 71.93H62V48.44c0-9.85 2.66-16.59 16.39-16.59z"
          fill="#b5852f"
          data-name="Capa 1"
        />
      </g>
    </svg>
  )
}

export default FacebookLogo
