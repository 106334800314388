import React from 'react';
import { Link } from 'react-router-dom';
import { FooterSection, TaomSection, MediaSection, ServicesSection, SocialMediaLogos, CallToAction, ServicesList } from '../styles/components/Footer.styled';
import LogoDoradoNegro from './svg/LogoDoradoNegro';
import FacebookLogo from './svg/socialmedia/FacebookLogo';
import InstagramLogo from './svg/socialmedia/InstagramLogo';
import GithubLogo from './svg/socialmedia/GithubLogo';
import LinkedinLogo from './svg/socialmedia/LinkedinLogo';
import SoundCloudLogo from './svg/socialmedia/SoundCloudLogo';
import TwitterLogo from './svg/socialmedia/TwitterLogo';
import YoutubeLogo from './svg/socialmedia/YoutubeLogo';
import WhatsAppLogo from './svg/socialmedia/WhatsAppLogo';

const Footer = () => {
  return (
    <FooterSection>
      <TaomSection>
        <Link to="/">
          <LogoDoradoNegro />
        </Link>
      </TaomSection>
      <MediaSection>
        <h3>Síguenos</h3>
        <SocialMediaLogos>
          <FacebookLogo width="35px" height="35px" />
          <InstagramLogo width="35px" height="35px" />
          <GithubLogo  width="35px" height="35px"/>
          <LinkedinLogo  width="35px" height="35px"/>
          <SoundCloudLogo  width="35px" height="35px"/>
          <YoutubeLogo  width="35px" height="35px"/>
          <TwitterLogo width="35px" height="35px" /> 
        </SocialMediaLogos>
        <CallToAction type="button">
          <span>
            Let's Talk
            <WhatsAppLogo width="25px" height="25px" />
          </span>
        </CallToAction>
      </MediaSection>
      <ServicesSection>
        <ServicesList>
          <Link to=""><li>Servicios</li></Link>
          <Link to=""><li>Política de privacidad</li></Link>
          <Link to=""><li>Aviso Legal</li></Link>
          <Link to=""><li>Términos y condiciones</li></Link>
        </ServicesList>
      </ServicesSection>
    </FooterSection>
  );
}
 
export default Footer;