import * as React from "react"

function InstagramLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 179.89 179.89"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:#b5852f}"}</style>
      </defs>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__Capa_1-2" data-name="Capa 1">
          <path
            className="prefix__cls-1"
            d="M131.2 0H48.69A48.74 48.74 0 000 48.69v82.51a48.74 48.74 0 0048.69 48.69h82.51a48.74 48.74 0 0048.69-48.69V48.69A48.74 48.74 0 00131.2 0zm38.14 131.2a38.18 38.18 0 01-38.14 38.14H48.69a38.18 38.18 0 01-38.14-38.14V48.69a38.18 38.18 0 0138.14-38.14h82.51a38.18 38.18 0 0138.14 38.14z"
          />
          <path
            className="prefix__cls-1"
            d="M90 40.76A49.19 49.19 0 10139.13 90 49.24 49.24 0 0090 40.76zm0 87.83A38.65 38.65 0 11128.59 90 38.69 38.69 0 0190 128.59zM140.31 23.29a14.54 14.54 0 1014.53 14.53 14.55 14.55 0 00-14.53-14.53zm0 18.52a4 4 0 114-4 4 4 0 01-4 4z"
          />
        </g>
      </g>
    </svg>
  )
}

export default InstagramLogo
