import * as React from "react"

function WhatsAppLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.87 109.39"
      {...props}
    >
      <g data-name="Capa 2">
        <path
          d="M93 15.9A54.22 54.22 0 007.69 81.3L0 109.39l28.74-7.54a54.08 54.08 0 0025.9 6.6A54.23 54.23 0 0093 15.9zM54.66 99.3A45.12 45.12 0 0131.71 93l-1.64-1L13 96.51l4.55-16.62-1.08-1.71A45.06 45.06 0 1154.66 99.3zm24.72-33.75c-1.36-.67-8-3.95-9.26-4.4s-2.14-.68-3.05.68-3.5 4.4-4.29 5.31-1.58 1-2.93.33A36.66 36.66 0 0149 60.75a40.67 40.67 0 01-7.54-9.38c-.79-1.36 0-2 .6-2.76A38.82 38.82 0 0045.4 44a2.49 2.49 0 00-.11-2.37c-.34-.68-3-7.34-4.18-10.06s-2.21-2.28-3-2.32-1.7 0-2.6 0a5 5 0 00-3.61 1.7 15.17 15.17 0 00-4.74 11.29c0 6.67 4.85 13.11 5.53 14s9.51 14.52 23.1 20.39a77.61 77.61 0 007.72 2.85A18.56 18.56 0 0072 80c2.6-.39 8-3.28 9.14-6.44s1.13-5.88.79-6.44-1.2-.89-2.55-1.57z"
          fill="#b5852f"
          fillRule="evenodd"
          data-name="Capa 1"
        />
      </g>
    </svg>
  )
}

export default WhatsAppLogo
