import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import DalePlay from './pages/DalePlay';
import Servicios from './pages/Servicios';
import WatchThis from './pages/WatchThis';
import MakeItPossible  from './pages/MakeItPossible';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/dale-play" exact>
          <DalePlay />
        </Route>
        <Route path="/servicios" exact>
          <Servicios />
        </Route>
        <Route path="/watch-this" exact>
          <WatchThis />
        </Route>
        <Route path="/make-it-possible" exact>
          <MakeItPossible />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
  