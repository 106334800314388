import styled from 'styled-components';
import Onda340 from '../../assets/svg/Recurso340.svg';

export const IntroWrapper = styled.div`
  color: ${props => props.color || "red"};
  padding: 7%;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  min-height: 30vh;
  z-index: -1;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 110%;
    left: -30%;
    z-index: -1;
    background: url(${Onda340}) 0 0 no-repeat;
    transform: rotate(335deg) scale(2);
  }

  h1 {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 3em;
  }

  p {
    color: #dfc294;
    margin-bottom: 15vh;
    font-family: 'Lato', sans-serif;
  }

  button {
    color: #3e1a44;
    border: none;
    border-radius: 25px;
    padding: 0.75em;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.6em;
    width: 30%;
    background-color: #dfc294;
    align-self: center;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.5em;
    min-height: 50vh;

    p {
      text-align: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 90%;
      left: -30%;
      transform: rotate(335deg) scale(1.5);
    }
  }
`;

export const ContentWrapper = styled.div`
  background-color: white;
  color: ${props => props.color || "red"};
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  p {
    line-height: 1.2em;
  }

  > div {
    padding: 7%;
    display: flex;
    flex-direction: column;
    height: 100%;

    h1 {
      font-family: 'Poppins', sans-serif;
      text-transform: uppercase;
      font-size: 3em;
      margin: 0;
    }

    marquee {
      h1 {
        font-weight: 400;

        span {
          margin: 0 0 0 5vw;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
  }
`;
