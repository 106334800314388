import styled from 'styled-components';
import Onda168 from '../../assets/svg/Recurso168.svg';
import Onda171 from '../../assets/svg/Recurso171.svg';
import Onda340 from '../../assets/svg/Recurso340.svg';

export const HeroWrapper = styled.div`
  background-color: white;

  > div {
    background-image: url(${Onda168}), url(${Onda171});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    text-align: left;
    display: flex;
    align-items: center;
    height: 100%;
  }

  h1 {
    padding: 7%;
    text-transform: uppercase;
    color: #3e1a44;
    font-size: 3em;
    line-height: 1.15em;
    font-weight: bolder;
    margin: 0;
  }

  h2 {
    display: none;    
  }

  @media screen and (min-width: 768px) {
    height: 90vh;

    > div {
      background-size: 90%;
      display: grid; 
      grid-template-columns: 0.3fr 1.2fr 1.2fr 0.3fr; 
      grid-template-rows: 1fr 1fr 0.5fr 1fr; 
      gap: 25px 25px; 
    }

    h1 {
      grid-area: 2 / 2 / 3 / 3;
    }

    h2 {
      display: block;
      grid-area: 3 / 3 / 4 / 4;
      font-size: 2em;
      color: #b5852f;
      font-weight: lighter;
      text-align: right;

      span {
        color: #3e1a44;
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 7%;
  text-align: center;
  color: #dfc294;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  min-height: 30vh;
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 110%;
    left: -30%;
    z-index: -1;
    background: url(${Onda340}) 0 0 no-repeat;
    transform: rotate(335deg) scale(2);
  }

  span {
    color: #b5852f;
  }

  p {
    color: #dfc294;
    text-align: justify;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.5em;
    min-height: 50vh;

    p {
      text-align: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 90%;
      left: -30%;
      transform: rotate(335deg) scale(1.5);
    }
  }
`;
