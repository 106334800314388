import React from 'react';
import WorkInProgress from './WorkInProgress';

function MakeItPossible(){
  return (
    <WorkInProgress page="MakeItPossible"/>
  );
};

export default MakeItPossible;
