import React from 'react';
import { IntroWrapper, ContentWrapper } from '../styles/pages/Services.styled';
import Contents from '../contents/data.json' 

const services_data = Contents.services
                                                                                                                                                                                                                                                                                                                                                          
function Servicios(){
  return (
    services_data.map(function(item, i){
      return( 
        <div>
          <IntroWrapper color={item.black.color}>
            <h1>{item.black.title}</h1>
            <p>{item.black.bodycontent[0].text}</p>
            <button>{item.black['add-ons'].button.text}</button>
          </IntroWrapper>
          <ContentWrapper color={item.white.color}>
            <div>
              <h1 >{item.white.title}</h1>  
              <marquee><h1>{item.white['title-words'].map((i) => <span>{i}</span> )}</h1></marquee>
              {item.white.bodycontent.map(function(bodyItem, j){
                return (
                  <div>
                    <h3>{bodyItem.title}</h3>
                    <p>{bodyItem.text}</p>
                    <div>{Object.keys(bodyItem.media).map((mediaKind, idx) => {
                      if (mediaKind === "video") {
                        return (
                          <iframe title={idx} width="420" height="315"
                            src={bodyItem.media[mediaKind].link} >
                          </iframe>
                        );
                      } else if (mediaKind === "image"){
                        return (
                          <img src={bodyItem.media[mediaKind].src} alt="imagen" />
                        )
                      } else {
                        return "chingo a mi madre"
                      }
                    })}</div>
                  </div>
                )
              })}
            </div>
          </ContentWrapper>
        </div>
      )
    })
  );
};

export default Servicios;
