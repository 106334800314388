import * as React from "react"

function GithubLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201.7 197.5"
      {...props}
    >
      <g data-name="Capa 2">
        <path
          d="M100.84 0C45.16 0 0 45.33 0 101.25a101.23 101.23 0 0069 96.07c5 .94 6.88-2.19 6.88-4.87 0-2.41-.08-8.78-.13-17.22-28.05 6.11-34-13.58-34-13.58-4.57-11.7-11.19-14.81-11.19-14.81-9.17-6.28.68-6.15.68-6.15 10.12.71 15.44 10.43 15.44 10.43 9 15.47 23.61 11 29.36 8.41.91-6.54 3.52-11 6.4-13.54-22.38-2.56-45.93-11.24-45.93-50A39.21 39.21 0 0146.9 68.78c-1-2.56-4.51-12.86 1-26.79 0 0 8.47-2.72 27.73 10.38a95.05 95.05 0 0150.5 0C145.36 39.28 153.82 42 153.82 42c5.5 14 2 24.23 1 26.79A39.18 39.18 0 01165.19 96c0 38.89-23.57 47.45-46 50 3.62 3.13 6.85 9.3 6.85 18.74 0 13.54-.13 24.46-.13 27.78 0 2.71 1.82 5.86 6.94 4.86a101.25 101.25 0 0068.89-96.06C201.7 45.33 156.54 0 100.84 0z"
          fill="#b5852f"
          data-name="Capa 1"
        />
      </g>
    </svg>
  )
}

export default GithubLogo
