import React from 'react';
import taomnegroblanco from '../assets/svg/taomnegro.svg';
import '../styles/WorkInProgress.css';

const WorkInProgress = ({ page }) => {
  return (
    <>
      <div className="App-header content">
        <img src={taomnegroblanco} className="App-logo" alt="logo" />
        <p>
          Próximamente  {page}
        </p>
        <a
          className="App-link"
          href="https://taom.mx"
          target="_blank"
          rel="noopener noreferrer"
        >
          <code>taom.mx</code> 
        </a>
      </div>
    </>
  );
}
 
export default WorkInProgress;